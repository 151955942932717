export default {
    state: {
        menu: [

            { product: true, title: 'Bots', bgcolor: 'primary' },
            {
                title: 'All Projects', icon: 'mdi mdi-format-list-group', href: '/bot/project',
            },
            {
                title: 'Input Parameters Config', icon: 'mdi-dns-outline', href: '/bot/ipconfig',
            },
            {
                title: 'File Master ', icon: 'mdi-file-multiple-outline ', href: '/bot/filemaster',
            },
            {
                title: 'File Config ', icon: 'mdi-file-link ', href: '/bot/filelist',
            },
            {
                title: 'Queries', icon: 'mdi-help-rhombus-outline  ', href: '/bot/queries',
            },
            // {
            //     title: 'File List', icon: 'mdi-file-arrow-left-right-outline ', href: '/bot/filelist',
            // },
        ],
    },
    mutations: {
    },
    actions: {
    },
    getters: {

    },
}